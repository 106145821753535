html,
body {
    font-family: 'Poppins', sans-serif;
}

body.rtl .upper-title-1 i {
    right: -2rem;
    left: auto;
}

body.rtl .form-group ngx-intl-tel-input input[type="tel"] {
    float: left;
}

body.rtl .iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
    right: 0;
}

body.rtl .form-group .invalid-feedback {
    text-align: right;
}

body.rtl .btn-style-5 i {
    margin-left: 0.7rem;
    margin-right: auto;
}

body.rtl .dropdown-menu.show {
    inset: 100% 0px auto 0px !important;
}

body.rtl .btn-style-10 {
    float: left;
    right: 5%;
    left: auto;
}

body.rtl ul.menu-style-1 li a i {
    left: 1.25rem;
    right: auto;
}

body.rtl .dashboard-left ul.menu-style-1 li a i {
    transform: rotate(180deg);
}

body.rtl ul.menu-style-2 li:last-child {
    margin-left: 0;
    margin-right: auto;
}

/* body.rtl .search-input i {
    right: 1rem;s
    left: auto;
} */

body.rtl .form-group input[type="checkbox"]:checked:before,
body.rtl ul li input[type="checkbox"]:checked:before {
    right: .1rem;
    left: auto;
}

body.rtl .form-group .position-relative .form-control {
    padding-right: 2.6rem;
    padding-left: auto;
}

body.rtl .input-search-zoom {
    right: 0.6rem;
    left: auto;
}

body.rtl .form-group .input-group-prepend {
    left: 1rem;
    right: auto;
}

body.rtl .form-group .input-tooltip {
    margin-right: 0.3rem;
    margin-left: auto;
}

body.rtl .form-group ngx-intl-tel-input .iti__flag-container .iti__selected-flag>div.iti__arrow,
body.rtl .form-group .mat-form-field .mat-select .mat-select-arrow {
    left: 1.2rem;
    right: auto;
}

body.rtl .form-group .mat-form-field-label {
    padding-right: .75rem !important;
    padding-left: auto;
}

body.rtl .form-group .mat-form-field-suffix {
    left: 0;
}

body.rtl .form-group .ng2-tag-input {
    margin-right: 1.6rem;
    margin-left: auto;
}

body.rtl .form-group code-input span:first-child {
    padding-right: 0 !important;
    padding-left: auto;
}

body.rtl .agreement-info .form-group input[type="checkbox"] {
    margin-left: 0.5rem;
}

body.rtl .wizard-stepper-block .agreement-info .btn-style-4:nth-child(1) {
    margin-right: 1.5rem;
}

body.rtl .attached-docs i {
    right: unset;
    left: 2rem !important;
}

body.rtl .fa-th {
    right: 44rem !important;
}

body.rtl .form-group .mat-button-toggle-button {
    margin-left: 1px;
    margin-right: auto;
}

body.rtl .form-group .mat-button-toggle {
    margin-left: 1.5rem;
    margin-right: auto;
}

body.rtl .form-group .mat-group-style-1 .mat-button-toggle:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

body.rtl .form-group .mat-group-style-1 .mat-button-toggle:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

body.rtl .wizard-dropzone .form-group .dropzone.dz-started .dz-image {
    float: right;
    margin-left: 0.4rem;
    margin-right: auto;
}

body.rtl .wizard-dropzone .form-group .dropzone .dz-details .fa-eye {
    float: right;
}

body.rtl .agreement-info2-list ul.menu-style-2 li {
    width: unset !important;
}

body.rtl .sidebar-documents .wizard-dropzone .form-group .dropzone .dz-remove {

    margin-right: 0.5rem;
    z-index: 7777;

}

body.rtl .alertify-notifier.ajs-right .ajs-message.ajs-visible {
    left: 0;
    right: auto;
}

body.rtl .alertify-custom-msg .msg {
    text-align: right;
    margin-right: 1rem;
    margin-left: auto;
}

@media screen and (max-width: 960px) {
    body.rtl .alertify-custom-msg .msg-close {
        right: unset !important;
    }
}

body.rtl .alertify-custom-msg .msg-close {
    float: left;
    left: 1rem;
}

body.rtl .alertify .ajs-dialog .ajs-button.ajs-ok,
body.rtl .alertify .ajs-dialog .ajs-button.ajs-cancel {
    float: left;
}

body.rtl .custom-autocomplete {
    right: 0;
    left: auto;
}

body.rtl .search-input input[type="text"] {
    padding: 0 1.25rem 0 3.25rem;
}