@font-face {
  font-family: 'Cairo ExtraLight';
  src: url('../fonts/Cairo/Cairo-VariableFont_wght.ttf'),
    url('../fonts/Cairo/Cairo-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.isarabic {
  font-family: 'Cairo ExtraLight' !important;
}

body.rtl .confirm-payment .headlines .relative-position {
  text-align: right;
}
body.rtl .currency-tabs{
  float: left !important;
}
body.rtl .card-issue{
  text-align: right;
}
body.rtl .mat-mdc-form-field{
  text-align: right;
}
body.rtl .mdc-data-table__header-cell,
body.rtl .mdc-data-table__cell {
  text-align: right;
}
body.rtl .card-info-icon{
  margin-left: 1rem;
}

body.rtl .MainSearch .fa-th {
  margin-left: 1rem !important;
  margin-right: auto;
}

body.rtl .mat-mdc-paginator-container {
  direction: ltr;
}

body.rtl .topup-container .accountbalance-Info i {
  margin-left: 0.5rem !important;
}

body.rtl .categoriesMarket {
  margin-left: 7rem !important;
}

body.rtl .for-comp .title-style2 i,
body.rtl .for-indiv .title-style2 i {
  left: 0 !important;
  right: unset !important;
}

body.rtl .mconnect-container .secured-matensa {
  position: unset !important;
}

body.rtl {
  direction: rtl;
  font-family: 'Cairo ExtraLight';
}

body.rtl .for-indiv .title-style2 i {
  left: 0;
  right: unset !important;
}

body.rtl .Agreements-rightpart ngx-spinner .loading-text,
body.rtl .connections-container ngx-spinner .loading-text,
body.rtl .catalogue ngx-spinner .loading-text {
  transform: translate(-1%, -90%) !important;
}

body.rtl .events-item {
  text-align: right;
}

body.rtl .event-currency {
  left: 2rem;
  right: unset !important;
}

body.rtl .event-price span {
  margin-left: 0.5rem;
}

body.rtl .event-info {
  direction: ltr !important;
}

body.rtl .catalog-category-btn {
  float: left !important;
}

body.rtl .add-menu .fa-bell {
  margin-left: 1rem;
}

body.rtl .btn-gfts svg {
  margin-left: unset !important;
}

body.rtl .reservation-place-titles,
body.rtl .reservation-place-list {
  text-align: right;
}

body.rtl .catalogue .fa-arrow-right {
  transform: rotate(180deg);
  margin-right: 0.5rem !important;
}

body.rtl .event-info i {
  margin-right: 0.5rem !important;
}

body.rtl .event-price .row .col {
  padding-right: 1rem !important;
  padding-left: unset !important;
}

body.rtl .topup-container {
  text-align: right;
}

body.rtl .wizard-stepper-block.BookingDetails .back-button {
  left: unset !important;
  right: -3rem;
}

body.rtl .reservation-cell-1.pull-right {
  float: left !important;
}

body.rtl .connections-middle.catalogue .mt-5 button.danger {
  margin-right: -2rem !important;
}

body.rtl .BookingSystem {
  text-align: right;
}

body.rtl .topup-container .back-button {
  margin-right: 1rem !important;
}

body.rtl .outstandingList .service-price {
  left: 10rem !important;
}

body.rtl .right-sidebar .cart-items-list .trash {
  right: unset !important;
  left: 2rem !important;
}

body.rtl .headlines .fal {
  right: unset;
  left: 0.5rem;
}

body.rtl .name_label {
  margin-top: 0.5rem;
  margin-right: 1rem !important;
}

body.rtl .cart-head {
  margin-left: 25px;
}

body.rtl .Item-Block .trash {
  left: 5rem !important;
  right: unset !important;
}

body.rtl .Item-Block i {
  left: 0 !important;
  right: unset;
}

body.rtl .table-one {
  text-align: right !important;
}

body.rtl .gift-history .heading-3 img {
  left: 0;
  right: unset !important;
}

body.rtl .gift-history .relative-position {
  margin-left: unset !important;
  margin-right: auto !important;
}

body.rtl .gifts-info {
  left: 3rem !important;
  right: unset !important;
}

body.rtl .products-style-1 {
  text-align: right;
}

body.rtl .View-Item-container {
  text-align: right;
}

body.rtl .prepaid-voucher {
  text-align: right;
}

body.rtl .SeeHistory {
  margin-left: 4rem;
}

body.rtl .childrenitems {
  margin-right: -0.8rem;
}

body.rtl .category-bx {
  text-align: right;
}

body.rtl .order-details-summary {
  margin-left: -2rem !important;
  margin-right: auto !important;
}

body.rtl owl-carousel-o {
  direction: ltr !important;
}

body.rtl .catalog-titles {
  text-align: right;
}

body.rtl .category-bx {
  text-align: right;
}

body.rtl .mconnect-sign {
  left: unset !important;
}

body.rtl .mconnect-container .connection-info {
  margin-right: 1rem;
}

body.rtl .mconnect-container .transaction-actions {
  margin-right: 4rem !important;
}

body.rtl .gift-history {
  text-align: right;
}

body.rtl .gift-history table thead tr:first-child th:first-child,
body.rtl .gift-history table th:last-child {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

body.rtl .gift-history th.mat-header-cell {
  text-align: right;
  padding-right: 1rem;
}

body.rtl .gift-history td.mat-cell {
  padding-right: 1rem;
}

body.rtl .financial-item {
  text-align: right;
}

body.rtl .connections-mainInfo .info-block2 i {
  margin-right: 0.6rem;
  margin-left: unset !important;
}

body.rtl .financial-item .invited {
  left: 0;
  right: unset !important;
}

body.rtl .catalogue-item .col-4:nth-child(1) {
  border-left: 1px solid #d7d3d3bc;
  border-right: unset;
}

/* body.rtl .catalogue-selection .owl-theme .owl-nav .owl-next {
  left: 5rem;
  right: unset !important;
  position: absolute;
} 
body.rtl .owl-carousel .owl-item {
  float: right !important;
} */
body.rtl .owl-carousel {
  direction: ltr !important;
}

body.rtl .catalogue-selection .owl-theme .owl-nav .owl-next {
  right: 1rem !important;
}

body.rtl .catalogue-selection .owl-theme .owl-nav .owl-prev {
  left: 3rem !important;
}

body.rtl .Receipt .sender-logo img {
  margin-right: 1rem !important;
}

body.rtl .headlines .connection-actions-options {
  text-align: right;
}

body.rtl .view-cart-sidebar .modal-actions {
  width: 80% !important;
}

body.rtl .headlines .fixed-fee {
  margin-right: auto;
  text-align: left;
  padding-left: 1rem;
}

body.rtl .headlines .connection-actions-options i {
  right: unset !important;
  left: 0.5rem;
}

body.rtl .Receipt-Details a {
  right: 7rem;
  left: unset !important;
}

body.rtl .reservations-working-date,
body.rtl .reservations-prices {
  text-align: right;
}

body.rtl .working-hour-item label {
  margin-left: 1rem;
}

body.rtl .reservations-prices .form-group .input-group-prepend {
  top: 50% !important;
}

@media screen and (max-width: 1025px) {
  body.rtl .BookingSystem .sorting-dropdown .Sort-agreements {
    right: -1rem !important;
  }

  body.rtl .Booking-Buttons .mr-3 {
    margin-left: unset !important;
  }

  body.rtl .view-cart-sidebar .right-sidebar .modal-actions {
    bottom: 3rem !important;
    right: 3rem !important;
  }

  body.rtl .cart-items-list {
    text-align: right;
  }

  body.rtl .connections-profile-actions .row:nth-of-type(1) {
    margin-left: 1rem;
    margin-right: unset !important;
  }

  body.rtl .cart-items-list .cart-item .ml-3 {
    margin-right: unset !important;
  }
}

body.rtl .cart-item .ml-3 {
  margin-right: 1rem !important;
}

body.rtl .catalogue-item .col-4:last-child {
  border-right: 1px solid #d7d3d3bc;
  border-left: unset;
}

body.rtl .financial-item .invoice-date {
  right: unset !important;
  left: 0 !important;
}

body.rtl .add-menu {
  left: 3rem;
  right: unset !important;
}

body.rtl .hideCatalogue .switch {
  margin-right: 1rem;
}

body.rtl .Item-Block.ToSelect {
  margin-right: 3rem;
}

body.rtl .createForm {
  text-align: right;
}

body.rtl .cart-item-price {
  left: 1rem;
}

body.rtl .form-group .view-cart-label {
  left: 0rem;
  right: unset !important;
}

body.rtl .cart-item-title {
  margin-right: 0.5rem;
}

body.rtl .view-cart-sidebar .modal-actions {
  left: unset !important;
}

@media screen and (min-width: 960px) {
  body.rtl .user-auth-right .profile-brief .bottom-actions {
    right: 35% !important;
  }
}

body.rtl .view-agr-right {
  border-right: 1px solid #e1e1e1;
  border-left: unset !important;
  padding-right: 2rem;
}

body.rtl .fa-copy {
  margin-left: unset !important;
  margin-right: 1rem;
}

body.rtl .view-customers .sorting-dropdown:nth-child(3) {
  margin-right: unset;
}

body.rtl .info-block img {
  margin-right: 0.8rem;
}

body.rtl .Payment-sched .imports-listing .connection-item .connection-actions,
body.rtl .Payment-sched.connections-middle .imports-listing .connection-item .fas {
  left: 0;
  right: unset !important;
}

body.rtl .helpsection-answers {
  text-align: right;
}

body.rtl .navbar-AccountProfiles:before,
body.rtl .navbar-AccountProfiles:after {
  left: auto;
  right: 1.6rem;
}

@media screen and (max-width: 960px) {
  body.rtl .app-logo img {
    width: 90% !important;
  }

  body.rtl .connections-middle .connections-list .connection-actions-options {
    right: auto !important;
    left: 0 !important;
  }
}

body.rtl .delete-account {
  float: left;
  right: auto;
  left: 0;
}

body.rtl .myCustomModalClass .modal-header .close {
  left: 2.2rem;
  right: auto;
}

body.rtl .myCustomModalClass .wizard-dropzone .form-group .dropzone .dropzone-text {
  text-align: right;
  padding-right: auto;
  padding-left: 1rem;
}

body.rtl .myCustomModalClass .wizard-dropzone .form-group .dropzone .dropzone-text .or {
  left: 1rem;
  right: auto;
}

body.rtl .myCustomModalClass .modal-actions {
  text-align: center;
}

body.rtl .wizard-dropzone .form-group .dz-button:after {
  content: "تصفح";
}

body.rtl .share-notice {
  text-align: right;
}

body.rtl .share-notice .img {
  float: right;
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .sharing-link input {
  padding-left: 5.7rem;
  padding-right: 1rem !important;
}

body.rtl .sharing-link a {
  left: 0.5rem;
  right: auto;
}

body.rtl .sharing-tools-slider {
  padding-right: 3rem;
  padding-left: auto;
}

body.rtl .user-auth-right .steps-container {
  right: -29rem;
  left: auto;
}

body.rtl .user-auth-right {
  float: left;
  left: 9rem;
  right: auto;
}

body.rtl .user-auth-form {
  float: right;
}

body.rtl .user-auth-top .left-layer {
  float: right;
  width: unset;
}

body.rtl .modal-agr {
  text-align: right;
}

body.rtl .heading-7 {
  text-align: right;
}

body.rtl .wizard-stepper-block .heading-7 {
  text-align: right;
  margin-right: 3rem;
}

body.rtl .user-auth-top .right-layer {
  float: left;
}

body.rtl .step-1-info span,
body.rtl .step-2-info span,
body.rtl .step-3-info span {
  margin-right: 0.5rem;
}

body.rtl .float-right {
  float: revert !important;
}

body.rtl .heading-layer {
  text-align: right;
}

body.rtl .security-message {
  right: 6.9rem;
  left: auto;
  text-align: right;
}

body.rtl .security-message .heading-4 span {
  margin-right: 0.7rem;
  margin-left: auto;
}

body.rtl .user-auth-top .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}

body.rtl .welcome-bottom-layer img {
  margin-right: -4.3rem;
  margin-left: auto;
}

body.rtl .step-1-info {
  right: -17rem;
  left: auto;
}

body.rtl .step-2-info {
  right: -34rem;
  left: auto;
}

body.rtl .step-3-info {
  right: -17rem;
  left: auto;
}

body.rtl .Payfrom-account i {
  line-height: 2.5rem;
}

body.rtl .form-group .input-group-prepend {
  top: 46%;
  transform: translateY(-50%);
}

.myCustomModalClass .modal-header .close {
  line-height: 3rem;
  font-size: 1.5rem;
  border: unset;
}

.dashboard-actions {
  text-align: center;
}

body.rtl .blockSuggestion div {
  text-align: right;
}

@media screen and (min-width: 960px) {

  body.rtl .user-auth-right .bottom-actions,
  body.rtl .user-auth-right .bottom-actions {
    right: 50%;
    left: auto;
  }

  body.rtl app-contactform .bottom-actions,
  body.rtl app-register .bottom-actions,
  body.rtl app-verify-account .bottom-actions {
    right: 34% !important;
    left: auto;
  }
}

body.rtl .map-layer-info label div {
  float: right
}

body.rtl .map-layer-info label i {
  margin-left: 1rem;
  margin-right: auto;
  float: right;
}

body.rtl .wizard-inquiry {
  left: 6rem;
  right: auto;
}

body.rtl .wizard-inquiry .question-icon {
  left: 2.5rem;
  right: auto;
}

body.rtl .wizard-left {
  float: right;
}

body.rtl .steps-agreement .step-circle:after {
  border-left: 0.5px solid #1c008a8c;
  border-right: unset;
}

body.rtl .step-number {
  right: 22rem;
  left: auto;
}

body.rtl .address-form-container {
  float: right;
}

body.rtl .sidebar-documents {
  right: 100%;
  padding: 2rem;
  left: auto;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

body.rtl .sidebar-documents.show {
  right: auto;
  left: 0;
}

body.rtl .modal-backdrop {
  right: 0;
  left: auto;
}

body.rtl .documents-form-container .upload-docs {
  left: -8rem;
  right: auto;
}

body.rtl .connections-middle .multiple-docs .document-title {
  margin-right: 1rem;
  margin-left: auto;
}

body.rtl .navbar-iconslist li:hover .share-connection-profile .profile-image-options {
  right: 8rem !important;
  left: auto;
}

body.rtl .documents-list .fa-folder {
  right: 0;
  left: auto;
}

body.rtl .close-documentsform {
  left: 1rem;
  right: auto;
}

body.rtl .back-button {
  margin-left: 1rem;
  margin-right: auto;
  transform: rotate(180deg);
}

body.rtl .documents-form-container .documents-list {
  padding-right: 1rem;
  padding-left: auto;
}

body.rtl .centerMarker img {
  right: 0;
  left: auto;
}

body.rtl .centerMarker:before,
body.rtl .centerMarker:after {
  right: 0px;
  left: auto;
}

body.rtl .map-center-overlay {
  right: 50%;
  left: auto;
}

body.rtl .social-media-connect {
  padding-right: 3rem;
  padding-left: auto;
}

body.rtl .agreement-summary .action-link.primary {
  left: 2rem;
  right: auto;
}

body.rtl .pac-container {
  font-family: 'Roboto', sans-serif;
}

body.rtl .pac-container .pac-item {
  font-family: 'Roboto', sans-serif;
}

body.rtl .dashboard-bluebg {
  right: 0;
  left: auto;
}

body.rtl .dashboard-middle {
  margin-right: 0.75rem;
  margin-left: auto;
}

body.rtl .connections-top app-import-clients-popup .btn-style-5 {
  left: 8rem;
  right: auto;
}

body.rtl .address-actions {
  text-align: left;
  margin-right: 1rem;
  margin-left: auto;
}

body.rtl .search-people {
  text-align: right;
}

body.rtl .connections-middle .heading-3 {
  text-align: right;
}

body.rtl .dashboard-left ul li label {
  float: right;
  padding-right: 1rem;
  padding-left: auto;
  text-align: right;
}

body.rtl .load-more i {
  right: 3rem;
  left: auto;
}

body.rtl .profile-pic a {
  left: 0.4rem;
  right: auto;
}

body.rtl .verified-icon i:last-child {
  right: 0.35rem;
  left: auto;
}

body.rtl .profile-middle-menu {
  border-left: 1px solid #CDCACA;
  border-right: unset;
}

body.rtl .profile-middle-menu ul.menu-style-1 li a i {
  left: 0.5rem;
  right: auto;
}

body.rtl .profile-progress-completed {
  right: 0;
  left: auto;
}

body.rtl .profile-left-content .btn-style-5:hover .profile-image-options {
  right: 4rem;
  left: auto;
}

body.rtl .share-connection-profile .btn-style-5.mr-3:hover .profile-image-options {
  right: -14rem;
  left: auto;
}

body.rtl .profile-progress-completed i {
  left: -13px;
  right: auto;
  float: left;
}

body.rtl ul.menu-style-1 li a .fa-check-circle {
  left: unset;
  right: auto;
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .connections-middle .profile-verified-notice {
  margin-left: auto;
  margin-right: auto;
}

body.rtl .wizard-form-container .form-group .mat-form-field .mat-select .mat-select-arrow {
  left: 1.8rem !important;
  right: auto !important;
}

body.rtl .address-info {
  float: right;
}

body.rtl .info-field {
  float: right
}

body.rtl .user-dashboard-middle .labelstyle-2 {
  text-align: right;
}

body.rtl .settings-container .settings-bottom-buttons .btn-style-2 {
  margin-left: unset !important;
}

body.rtl .profile-contact-info .form-group .action-link,
body.rtl .address-actions,
body.rtl .left-actions .btn-style-1,
body.rtl .agreement-selected-accounts>a,
body.rtl .Float-right-elements {
  float: left
}

body.rtl .left-actions .btn-style-1 {
  margin-right: 0.3rem;
}

body.rtl .helpsection-questions-block {
  text-align: right;
}

/* body.rtl .btn-style-9 .Float-right-elements {
  margin-left: 5rem;
} */

body.rtl .contact-info-layer .form-group.verified .info-field i,
body.rtl .contact-info-layer .form-group.not-verified .info-field i {
  right: 0.2rem;
  left: auto;
}

body.rtl .contact-info-layer .form-group .info-field span:not(.iti__country-name):not(.iti__dial-code) {
  left: 3.1rem;
  right: auto;
}

body.rtl .profile-middle-content .bottom-actions {
  padding-right: 2.5rem;
  padding-left: auto;
}

body.rtl .agreements-stepper .bottom-actions {
  float: right;
}

body.rtl .profile-middle-content .wizard-dropzone .bottom-actions {
  padding-right: 0;
  padding-left: auto;
}

body.rtl .form-group input[type="checkbox"],
body.rtl ul li input[type="checkbox"] {
  margin-left: 0.5rem;
}

body.rtl fieldset div.agr-summary {
  text-align: right;
}

body.rtl .share-connection-profile {
  left: 5rem;
  right: auto;
}

body.rtl .connections-middle .btn-style-5 {
  float: left
}

body.rtl .connections-container .filter-actions span {
  float: right;
}

body.rtl .connections-container .heading-3 {
  text-align: right;
}

body.rtl .connection-labelstyle1,
body.rtl .settings-labelstyle1 {
  text-align: right;
}

body.rtl .refresh {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .modal-actions .btn-style-1,
body.rtl .modal-actions .btn-style-2 {
  margin-right: 0.625rem;
  margin-left: auto;
}

body.rtl .modal-actions .btn-style-4 {
  float: right;
}

body.rtl .connections-top .btn-style-5 {
  left: 0;
  right: unset !important;
}

body.rtl .dashboard-left-carousel {
  direction: ltr !important;
}

body.rtl .dashboard-balance {
  direction: ltr !important;
}

body.rtl .tutorials-carousel {
  direction: ltr !important;
}

/* body.rtl .connections-top .btn-style-5:first-of-type {
 margin-left: 7rem;
} */
body.rtl .balance-allocation {
  direction: rtl !important;
}

body.rtl .connection-item-right {
  margin-left: unset;
  margin-right: 1rem;
}
body.rtl .UploadExcelMain .heading-3{
  margin-right: 1rem !important;
  margin-bottom: 1rem;
}
body.rtl .employee-transc i{
  left: 8rem;
  transform: rotate(180deg);
  right: unset;
}
body.rtl .transaction-report-bold{
  left: 5rem;
  right: unset;
}
body.rtl .UploadExcelMain{
  text-align: right;
}
body.rtl .UploadExcelMain  .Agreements-rightpart .connection-item .fas {
  right: unset;
  transform: rotate(180deg);
  left: 1rem !important;
}
body.rtl .connection-photo {
  float: right;
  margin-right: 0.5rem;
}

body.rtl .connection-info {
  float: right;
  max-width: 55%;
  text-align: right;
}

body.rtl .MyInvoices .connection-info {
  max-width: 45%;
}

body.rtl .MyInvoices .InvoicesList .connection-info {
  margin-right: 3rem;
}

body.rtl .payments-listing .connection-info {
  max-width: 75% !important;
}

body.rtl .fee-field {
  text-align: right;
}

body.rtl .connection-name span {
  right: 9rem;
  left: auto;
}

body.rtl .connection-actions-options a span {
  text-align: right;
}

body.rtl .connection-phone {
  margin-left: 1rem;
  margin-right: auto;
  direction: ltr;
}

body.rtl .connection-actions {
  float: left
}

body.rtl .connection-actions-options {
  left: -2.5rem;
  right: auto;
}

body.rtl .connection-tab {
  margin-right: 2rem;
  margin-left: auto;
}

body.rtl .dashboard-left ul li input[type="checkbox"] {
  float: right;
}

body.rtl .dashboard-left ul li.connection-li input[type="checkbox"] {
  margin-right: 0;
  margin-left: auto;
}

body.rtl .dashboard-left ul li input[type="checkbox"] {
  margin-right: 0;
}

body.rtl .dashboard-left ul li .connection-tab {
  margin-right: 0;
  margin-left: auto;
}

body.rtl .needsFilters .head-1 {
  text-align: right;
}

body.rtl .needsFilters .heading-3 {
  text-align: right;
  margin-right: 1rem;
}

body.rtl .UpperFilter .btn-style-5 {
  margin-right: 1rem;
}

body.rtl .Sort-agreements a span {
  text-align: right;
}

body.rtl .dashboard-left ul li .connection-tab .connection-info {
  padding-right: 0.4rem;
  padding-left: auto;
  margin-right: 0.5rem;
  margin-left: auto;
}

body.rtl .connection-tab .connection-photo {
  float: right;
  margin-left: 0;
  margin-right: auto;
}

body.rtl .import-container .note {
  right: 0;
  left: auto;
}

body.rtl .right-sidebar-shadow {
  left: 0;
  right: auto;
}

body.rtl .right-sidebar {
  right: 115%;
  left: auto;
}

body.rtl.show-import-sidebar .right-sidebar {
  right: 70%;
  left: auto;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

body.rtl .right-sidebar .modal-actions {
  right: 3rem;
  left: 0;
}

body.rtl ul li label input[type="checkbox"] {
  margin-left: 0.5rem;
  margin-right: auto;
}

@media screen and (min-width:960px) {
  body.rtl .user-auth-left {
    right: 0;
    left: auto;
  }

  body.rtl .agm-map-container-inner {
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }

  body.rtl .user-auth-top {
    left: 0;
    right: auto;
  }

  body.rtl .user-auth-top .left-layer .app-logo {
    right: 4.7rem;
    left: auto;
  }

  body.rtl .authentication-design {
    left: 0rem;
    right: auto;
  }

  body.rtl .wizard-left {
    right: 0;
    left: auto;
  }

  body.rtl .wizard-right {
    margin-right: 22rem;
    margin-left: auto;
  }

  body.rtl .step-circle .step-tab {
    padding-left: 5rem;
    padding-right: unset !important;
  }

  body.rtl .wizard-stepper-block .heading-7 {
    padding-right: 4rem;
  }

  body.rtl .step-circle:after {
    right: 0.4rem;
    left: auto;
  }

  body.rtl .welcome-bottom-layer {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }

  body:not(.has-scroll).rtl .wizard-pagination {
    right: 30rem;
    left: auto;
  }

  body:not(.has-scroll).rtl .wizard-pagination button.float-right {
    left: 5rem;
    right: auto;
  }

  body.rtl .wizard-pagination .btn-style-4 {
    right: 4.4rem;
    left: auto;
  }

  body.rtl .map-container {
    float: right;
  }

  body.rtl .myCustomModalClass.document-modal-popup .modal-header .close {
    left: -6px;
    right: auto;
  }
}

@media screen and (max-width:960px) {
  body.rtl .wizard-inquiry .question-icon {
    left: 6rem;
    right: auto;
  }

  body.rtl .dashboard-content .dashboard-container {
    margin-right: auto !important;
  }

  body.rtl .user-auth-right {
    right: 0;
    left: auto;
  }

  body.rtl .back-button {
    right: unset !important;
    left: auto;
  }

  body.rtl .agreement-info2-list ul.menu-style-2 li {
    width: 33% !important;
  }

  body.rtl .right-sidebar .modal-actions {
    right: 0rem !important;
    left: auto;
  }

  body.rtl .confirm-payment .headlines {
    left: unset !important;
    right: auto;
  }

  body.rtl .View-agreement-container .confirm-payment .cancelPay i {
    right: 0.35rem !important;
    left: auto;
  }

  body.rtl .confirm-payment .settings-bottom-buttons {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .middle-actions.agreement-actions .btn-style-2 {
    margin-right: 1rem !important;
    margin-left: auto;
  }

  body.rtl .wizard-inquiry {
    left: 0rem;
    right: auto;
  }

  body.rtl .step-circle:after {
    width: 80% !important;
    right: 4.5rem !important;
  }


  body.rtl .step-number {
    right: unset !important;
    left: auto;
  }

  body.rtl .user-auth-right .steps-container {
    right: unset;
    left: auto;
  }
}

body.rtl .connection-info-item .label-style-2 {
  direction: ltr;
}

@media screen and (max-width:480px) {
  body.rtl .social-media-connect .btn-style-1 {
    float: left;
  }
}

body.rtl .nav-header {
  padding-right: 3.5rem;
  padding-left: 2.5rem;
}

body.rtl .navbar-brand {
  margin-right: 7rem;
  margin-left: auto;
}

body.rtl .navbar-primary-list {
  margin-right: 6rem;
  margin-left: auto;
  text-align: right;
}

body.rtl .navbar-settings .fas-icon {
  margin-right: 0.85rem;
  margin-left: auto;
}

body.rtl .navbar-Dropdown {
  margin-left: 0;
  margin-right: auto;
  left: 0;
  right: auto;
}

body.rtl .navbar-settings .navbar-Dropdown {
  left: -4.4em;
  right: auto;
}

body.rtl .navbar-notifications .navbar-Dropdown {
  left: -12.7em;
  right: auto;
}

body.rtl .navbar-AccountProfiles {
  padding-right: 4.8rem;
  padding-left: auto;
}

body.rtl .navbar-AccountProfiles .navbar-accounts:before {
  right: -3.2rem;
  left: auto;
}

body.rtl .navbar-responsive .navbar-Dropdown {
  left: 5%;
  right: auto;
}

body.rtl .navbar-responsive li {
  margin-right: -0.5rem !important;
  padding-right: 1rem;
  padding-left: auto;
  margin-left: auto;
}

body.rtl .navbar-labelstyle1 {
  float: right;
  padding-right: 1rem;
  padding-left: auto;
}

body.rtl .navbar-labelstyle2 {
  margin-right: 0.8rem;
  margin-left: auto;
  float: right;
}

body.rtl .navbar-labelstyle3 {
  margin-right: 1rem;
  margin-left: auto;
  float: right;
}

body.rtl .navbar-labelstyle4 {
  margin-right: 1rem;
  margin-left: auto;
  float: right;
}

body.rtl .navbar-btnstyle1 {
  float: right;
  text-align: right;
}

body.rtl .new-notification {
  left: -0.3rem;
}

body.rtl .notifications-dropdown {
  padding: 1.5rem 1rem 2rem 0rem;
  text-align: right;
}

body.rtl .notification-block .connection-info {
  max-width: 65% !important;
}

body.rtl .notifications-dropdown .heading-5 {
  left: 2rem;
  right: auto;
}

body.rtl .notification-filters {
  float: left;
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .notification-filters .View-agr-options {
  right: unset !important;
  left: auto;
}

body.rtl .notification-filters .connection-actions-options {
  left: -3rem;
  right: auto;
  text-align: right;
}

body.rtl .notification-filters .Clear-All {
  left: 1rem;
  right: auto;
}

body.rtl .notification-filters .list-1 {
  margin-left: 2rem;
  margin-right: -1rem;
}

body.rtl .notification-title {
  right: 0.5rem;
  left: auto;
}

body.rtl .notification-block .connection-actions-options {
  left: -3.3rem;
  right: auto;
}

body.rtl .notification-block .View-agr-options {
  right: 90% !important;
  left: auto;
}

body.rtl .row .upper-title-1 i {
  right: -0.5rem !important;
  left: auto;
}

body.rtl .adjust:hover .profile-image-options {
  right: -12rem;
  left: auto;
}

body.rtl .Notifications-container .profiile-flex-container .adjust {
  left: 1rem;
  right: auto;
}

body.rtl .Notifications-container .notification-block .View-agr-options {
  right: 97% !important;
  left: auto;
}

body.rtl .Payment-sched .UpperFilter.Agreements-rightpart ul.list-types li {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .Payment-sched.connections-middle .menu-style-1 li .fa-chevron-up,
body.rtl .Payment-sched.connections-middle .menu-style-1 li .fa-chevron-down {
  left: 1rem;
  right: auto;
}

body.rtl .connections-mainInfo .connections-info-right {
  margin-left: auto;
  margin-right: 30%;
}

body.rtl .Invoices-Container .InvoiceDetails table {
  text-align: right;
}

body.rtl .confirm-payment .headlines .headingstyle2 {
  margin-left: 1rem;
}

body.rtl .Receipt {
  text-align: right;
}

body.rtl .alertify .ajs-dialog {
  text-align: right;
}

body.rtl .transactions-details .heading-3 a {
  margin-left: 1rem;
}

body.rtl .list-1.list-types li {
  display: unset !important;
}

body.rtl .transaction-actions {
  text-align: right;
}

body.rtl .roll-back {
  text-align: right;
  margin-bottom: 2rem;
  margin-right: 6rem !important;
}

body.rtl .helpsection-leftmenu {
  margin-right: -3rem;
  margin-left: auto;
}

body.rtl .helpsection-main-page .dashboard-middle {
  margin-right: 2.81rem;
  margin-left: auto;
}

body.rtl .heading-3 {
  text-align: right;
}

body.rtl .helpsection-leftmenu ul li {
  text-align: right;
}

body.rtl .helpsection-leftmenu ul li span {
  margin-right: 1.5rem;
}

body.rtl .fixed-helpMessage {
  right: 3%;
  left: auto;
  text-align: right;
  margin-right: 1rem;
}

body.rtl .helpsection-questions-icons {
  float: left;
  margin-left: unset !important;
  margin-right: auto;
}

body.rtl .Email-notifications .black-labels {
  padding-right: 0.6rem;
  padding-left: auto;
}

body.rtl .main-profile-settings .dashboard-settings-left ul {
  margin-right: -1.8rem !important;
  margin-left: auto;
}

body.rtl .main-profile-settings .dashboard-settings-left ul li a {
  margin-right: 1.5rem;
  margin-left: auto;
}

body.rtl .blockedaccounts-settings .btn-style-6 {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .blockedaccounts-settings .connection-info {
  margin-right: 1rem;
  margin-left: auto;
}

body.rtl .privacyAndSecurity .connection-info-container {
  margin-left: 0rem;
  margin-right: auto;
}

body.rtl .privacyAndSecurity .connections-info-right {
  margin-right: 1.8rem;
  margin-left: auto;
}

body.rtl .privacyAndSecurity .connections-info-left {
  padding-left: unset;
  border-left: 1px solid #CDCACA;
  padding-right: auto;
  border-right: unset;
  padding-left: 1.8rem;
}

body.rtl .role-managemenet-main .connection-actions-options {
  left: 0;
  right: auto;
}

body.rtl .role-managemenet-main .danger {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .role-managemenet-main .connection-info {
  margin-right: 1rem;
  margin-left: auto;
}

body.rtl .role-managemenet-main .connection-actions {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .role-managemenet-main .collection-item2 .connection-actions {
  margin-right: 0.5rem;
  margin-left: auto;
}

body.rtl .btn-share-profile span {
  margin-right: 1.1rem !important;
  margin-left: auto;
}

body.rtl .multiple-roles div button {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .settings-bottom-buttons-modal {
  float: left;
}

body.rtl .switch {
  margin-right: auto;
  margin-left: 1rem;
}

body.rtl .settings-notification .switch {
  margin-left: 4.2rem;
  margin-right: auto;
}

body.rtl .settings-permissions .sorting-dropdown {
  left: 5rem;
  right: auto;
}

body.rtl .logged-in-devices .danger {
  margin-right: auto;
  margin-left: unset;
}

body.rtl .edit-security-questions .settings-bottom-buttons-modal {
  float: left !important;
}

body.rtl .settings-profile-photo {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl ul.menu-style-1 li a {
  text-align: right;
}

body.rtl .settings-labelstyle3 {
  left: 6rem;
  right: auto;
}

body.rtl .role-management-container .bottom-actions {
  padding-right: unset;
  padding-left: auto;
}

body.rtl .dashboard-grey {
  border-top-left-radius: 100px;
  border-top-right-radius: unset;
  /* position: unset !important; */
  top: unset !important;
}

body.rtl .profile-image-options {
  right: 3rem;
  left: auto;
  text-align: right;
}

body.rtl .user-dashboard .dashboard-middle {
  margin-right: 1.5rem;
  margin-left: auto;
}

body.rtl .user-dashboard .dashboard-right {
  margin-right: 1.5rem;
  margin-left: auto;
}

body.rtl .percentage {
  margin-left: unset;
  margin-right: auto;
}

body.rtl .SeeAll-links {
  float: left;
}

body.rtl .Payment-sched .Agreements-rightpart .btn-style-5 {
  left: 5rem;
  right: auto;
}

body.rtl .item-details {
  margin-right: -4.5rem;
}

body.rtl .tutotials-scroll {
  float: left;
}

body.rtl .unread_notif {
  left: 2rem;
  right: unset !important;
}

body.rtl .accounts-list .unread_notif {
  right: unset !important;
  left: 0.2rem !important;
}

body.rtl .tutotials-scroll div {
  margin-right: 3rem;
  margin-left: auto;
}

body.rtl .dashboard-left-carousel .fas {
  left: -1rem;
  right: auto;
}

body.rtl .dashboard-collectedpoints {
  margin-right: 0.5rem;
  margin-left: auto;
}

body.rtl .dashboard-container {
  margin-right: 0rem !important
}

/* body.rtl .btn-style-9 span {
  margin-right: 1rem;
} */

body.rtl .dashboard-collectedpoints .fas {
  margin-left: unset;
  margin-right: auto;
  transform: rotate(180deg);
}

body.rtl .ListAgreements .sorting-dropdown .Sort-agreements {
  left: -3rem;
  right: auto;
}

body.rtl .address-name {
  text-align: right;
}

body.rtl .document-block .profile-image-options div i {
  margin-left: 1rem !important;
}

body.rtl .mat-option-text {
  text-align: right;
}

body.rtl .you-personalize {
  text-align: right;
}

body.rtl .app-modal-editpic .settings-bottom-buttons {
  float: left;
}

body.rtl .agreementRelated label div {
  margin-right: 2rem;
  margin-left: auto;
}

body.rtl .iti__flag-container .dropdown-menu.show {
  direction: ltr;
}

body.rtl .dashboard-middle.Pay-Now {
  margin-right: auto !important;
}

.AllAccounts .agreementRelated label div {
  margin-bottom: 0.3rem;
}

body.rtl .multiple-roles.modal-agr {
  text-align: right;
}

body.rtl .password-modal-container {
  text-align: right;
}

body.rtl .form-group {
  text-align: right;
}

body.rtl .form-note {
  text-align: right;
}

body.rtl .Invoices-Container .InvoiceDetails .settings-bottom-buttons .btn-style-5 {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .settings-container .profile-verified-notice {
  margin-right: auto;
  margin-left: unset !important;
}

body.rtl .payment-type app-import-clients-popup {
  margin-right: auto;
}

body.rtl .template-import {
  margin-right: auto;
}

@media only screen and (max-width: 1025px) {

  body.rtl .MyInvoices .connections-list.SelectTopay .connection-item,
  body.rtl .profile-verified-notice .connections-list.SelectTopay .connection-item {
    margin-right: 1rem !important;
    margin-left: auto;
  }

  body.rtl .payment-title {
    right: 0rem !important;
    left: auto;
  }
}

@media screen and (max-width :1260px) and (min-width: 1025px) {
  body.rtl .terms-types .types-title {
    text-align: right !important;
  }
}

@media screen and (max-width :800px) {
  body.rtl .agreement-payment-container .action-link.danger {
    float: left;
  }
}

@media screen and (max-width :1260px) and (min-width: 1025px) {
  body.rtl .terms-types .types-title {
    text-align: right !important;
  }
}

@media screen and (max-width :800px) {
  body.rtl .agreement-payment-container .action-link.danger {
    float: left;
  }

}

body.rtl .total-matensa {
  margin-right: 3rem;
}

body.rtl .InvoiceDetails {
  text-align: right;
}

@media screen and (max-width: 1025px) {
  body.rtl .agreement-amounts .btn-style-5 {
    line-height: 0.5rem !important;
  }

  body.rtl div.wizard-dropzone .bottom-actions .btn-style-2 {
    margin-left: unset !important;
  }
}

body.rtl .import-log {
  left: 7rem !important;
}

@media screen and (max-width :666px) {
  body.rtl .agr-invoices .settings-bottom-buttons .btn-style-2 {
    margin-left: unset !important;
  }

  body.rtl .total-matensa {
    text-align: right;
  }

  body.rtl .agr-invoices .settings-bottom-buttons {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .ViewAgr-Actions {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .agreement-info2-list .menu-style-2 {
    margin-right: -1rem;
    margin-left: auto;
  }

  body.rtl .View-agreement-container .Agreements-rightpart .col-3 {
    margin-left: unset !important;
    margin-right: auto;
  }

  body.rtl .agr-invoices .invoices-body {
    padding-right: unset !important;
    padding-left: auto;
  }

  body.rtl .MyInvoices .settings-bottom-buttons .btn-style-5 {
    margin-right: -2rem !important;
    margin-left: auto;
  }
}

@media (max-width: 1375px) and (min-width: 750px) {
  body.rtl .Agreements-rightpart .invited {
    right: 300%;
    left: auto;
  }
}

@media (min-width: 1715px) {
  body.rtl .sorting-dropdown .Sort-agreements {
    left: unset;
    right: auto;
  }
}

@media (min-width: 1400px) {
  body.rtl .Agreements-rightpart .list-types {
    margin-left: 21rem;
  }
}

@media screen and (max-width :1025px) {
  body.rtl .connections-top .btn-style-5 {
    float: right;
    margin-right: unset;
    margin-left: auto;
  }

  body.rtl .import-file .btn-style-5 {
    left: 1rem !important;
    right: auto;
  }

  body.rtl .view-customers .heading-3 {
    margin-right: 1rem !important;
    margin-left: auto;
  }

  body.rtl .dashboard-middle {
    margin-right: unset;
    margin-left: auto;
  }

  body.rtl .profile-middle-menu {
    border-left: unset;
    border-right: auto;
  }

  body.rtl .profile-middle-content .bottom-actions {
    padding-right: unset;
    padding-left: auto;
  }

  body.rtl .agreements-stepper .bottom-actions {
    float: unset !important;
  }

  body.rtl .role-management-add .bottom-actions {
    float: right;
  }

  body.rtl .notification-block .connection-actions-options {
    left: 0 !important;
    right: auto;
  }

  body.rtl .role-management-add .bottom-actions .btn-style-2 {
    margin-left: -1rem;
    margin-right: auto;
  }

  body.rtl .main-profile-settings .dashboard-settings-left ul li.active {
    border-right: unset;
    border-left: auto;
  }

  body.rtl .settings-info-container .no-documents-found {
    margin-right: unset;
    margin-left: auto;
  }

  body.rtl .Email-notifications .settings-info-container {
    padding-left: unset;
    padding-right: auto;
  }

  body.show-import-sidebar.rtl .right-sidebar {
    right: 0;
    left: auto;
  }

  body.rtl .user-dashboard .dashboard-right {
    margin-right: unset;
    margin-left: auto;
  }
}

@media screen and (max-width: 1025px) {
  body.rtl .Notifications-container .notification-block .View-agr-options {
    right: 93% !important;
    left: auto;
  }

  body.rtl .View-agreement-container .dashboard-left ul {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .share-connection-profile {
    left: 1rem;
    right: auto;
  }

  body.rtl .connection-labelstyle1 i:hover .profile-image-options {
    right: -9rem !important;
    left: auto;
  }

  body.rtl .privacyAndSecurity .connections-info-left {
    border-left: unset;
    padding-left: unset !important;
    padding-right: auto;
    border-right: auto;
  }

  body.rtl .connections-mainInfo .label-style-1,
  body.rtl .View-agreement-container .label-style-1 {
    text-align: right;
  }

  body.rtl .privacyAndSecurity .connections-info-right {
    margin-right: unset;
    margin-left: auto;
  }

  body.rtl .btn-style-4 span {
    margin-left: 1rem;
  }

  body.rtl .connection-labelstyle1,
  body.rtl .labelstyle-2 {
    margin-right: unset !important;
    padding-right: unset;
    text-align: right;
    margin-left: auto;
    padding-left: auto;
  }

  body.rtl .label-style-1 i {
    margin-right: 2rem;
  }

  body.rtl .form-group .form-control {
    text-align: right;
  }

  body.rtl .Email-notifications .black-labels {
    padding-right: unset !important;
    padding-left: auto;
  }

  body.rtl .settings-list {
    margin-right: -1rem !important;
    margin-left: auto;
  }

  body.rtl .terms-types label {
    margin-left: 0rem !important;
    margin-right: auto;
  }

  body.rtl .types-title {
    margin-left: unset !important;
    margin-right: auto;
  }

  body.rtl .agreement-amounts .VAT {
    margin-right: 0rem !important;
    margin-left: auto;
  }

  body.rtl .agreement-actions .btn-style-2 {
    margin-left: 0.5rem;
    margin-right: auto;
  }

  body.rtl .helpsection-leftmenu {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .profile-left-content .btn-style-5:hover .profile-image-options {
    right: 0rem !important;
    left: auto;
  }

  body.rtl .navbar-brand {
    margin-right: -2rem !important;
    margin-left: auto;
  }

  body.rtl .navbar-toggle-now {
    margin-left: -1.5rem;
    margin-right: auto;
  }

  body.rtl ul.agreement-menu {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .Fees {
    right: unset !important;
    left: auto;
  }

  body.rtl .templates-drafts .heading-3 {
    margin-right: 1rem !important;
    margin-left: auto;
  }

  body.rtl .heading-3 {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .profile-pic:hover .profile-image-options {
    right: -3rem;
    left: auto;
  }

  body.rtl app-agreements-main-fil .filters-responsive {
    left: 4.5rem;
    right: auto;
  }

  body.rtl .responsive-sorting {
    left: 1rem;
    right: auto;
  }

  body.rtl .responsive-sorting .Sort-agreements {
    left: 1rem !important;
    right: auto;
  }

  body.rtl .settings-labelstyle1 {
    margin-right: unset !important;
    margin-left: auto;
  }
}

@media screen and (max-width:760px) {
  body.rtl .settings-permissions .sorting-dropdown {
    right: 1.5rem;
    left: auto;
  }

  body.rtl .MyPayments .settings-permissions .sorting-dropdown {
    right: 1rem;
    left: auto;
  }

  body.rtl .sorting-dropdown .Sort-agreements {
    right: unset !important;
    left: -1rem !important;
  }

  body.rtl .InvoiceFooter {
    right: 1rem !important;
    left: auto;
  }
}

body.rtl .add-mobile {
  left: 43%;
  right: auto;
}

@media screen and (max-width:1025px) {
  body.rtl .needsFilters .dashboard-grey {
    margin-left: 1rem;
    margin-right: auto;
    left: 0rem;
    right: 1rem;
  }

  body.rtl .View-agreement-container .dashboard-middle {
    border-right: unset !important;
    border-left: auto;
  }
}

@media screen and (max-width:690px) {

  body.rtl .steps-agreement .far,
  body.rtl .steps-agreement .fas {
    right: 3.5rem !important;
    left: auto;
  }

  body.rtl .agreement-payment-container {
    margin-left: 1rem !important;
    margin-right: auto;
  }

  body.rtl .agreements-stepper ul.menu-style-2 {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .relative-position .Payments-Details {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .user-dashboard .connection-item-right {
    margin-right: 1rem !important;
    margin-left: auto;
  }

  body.rtl .user-dashboard .payments-listing .connection-item-right {
    margin-right: 1rem !important;
    margin-left: auto;
  }

  body.rtl .ListAgreements .InvoicesList .invoice-date {
    right: 70% !important;
    left: auto;
  }

  body.rtl .view-customers .btn-style-5 {
    margin-right: 1rem;
    margin-left: auto;
  }

  body.rtl .user-dashboard .MyInvoices .InvoicesList .image .invited {
    right: 70% !important;
    left: auto;
  }

  body.rtl .profile-middle-content.Transactions .heading-2 {
    margin-right: unset !important;
    margin-left: auto;
  }

  body.rtl .MyInvoices .Transactions .image .invited {
    right: 70% !important;
    left: auto;
  }

  body.rtl .settings-notification .switch {
    margin-left: unset;
    margin-right: auto;
  }

  body.rtl .user-dashboard-middle .connection-info {
    margin-right: 1rem;
  }

  body.rtl .user-dashboard-middle .InvoicesList .payment-method,
  body.rtl .user-dashboard-middle .InvoicesList .connection-phone,
  body.rtl .user-dashboard-middle .InvoicesList .connection-name {
    margin-right: unset !important;
  }
}

body.rtl .Clear-All {
  float: left;
}

body.rtl .list-types {
  margin-right: 0rem;
  margin-left: 19.5rem;
  padding-right: 0rem;
}

body.rtl .sorting-dropdown {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .View-agr-options {
  right: 96% !important;
  left: auto;
}

body.rtl .alertify .ajs-commands {
  left: 4px;
  right: unset !important;
}

body.rtl .ListAgreements span.invited {
  right: 70%;
  left: auto;
}

body.rtl .Payment-sched .invoices-body .btn-style-10 {
  left: 9%;
  right: auto;
}

body.rtl .agreement-info2 .sorting-dropdown .Sort-agreements {
  left: unset;
  right: auto;
}

body.rtl .view-customers .btn-style-5 {
  margin-left: 1rem;
}

body.rtl .sorting-dropdown .Sort-agreements {
  left: 4%;
  right: auto;
}

body.rtl .Agreements-rightpart .invited {
  right: 190%;
  left: auto;
}

body.rtl .Agreements-rightpart .agreement-status {
  right: 0rem;
  left: auto;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

body.rtl .menu-style-1 .relative-position a {
  right: -1.3rem;
  left: auto;
}

body.rtl .invoices-actions .dashboard-middle {
  border-right: unset !important;
  border-left: auto;
}

body.rtl .Payments-Actions a {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .Receipt-Icon {
  right: 90%;
  left: auto;
}

body.rtl .user-dashboard-middle .connection-info {
  max-width: 55% !important;
}

body.rtl .list-1.list-types li {
  margin-left: 0.5rem;
}

body.rtl .Agreements-rightpart .connection-item .fas {
  right: 2.5rem;
  left: auto !important;
}

body.rtl .agr-invoices .row1-summary .row .btn-style-5 {
  float: left;
}

body.rtl .MyInvoices .Agreements-rightpart .connection-item .fas {
  right: 1rem !important;
}

body.rtl .otherparty .connection-item:hover {
  border-left: 2px solid transparent;
  border-right: auto;
}

body.rtl .Agreements-rightpart .connection-item {
  border-right: 0px;
  border-left: auto;
}

body.rtl .agreements-container .heading-5 {
  margin-right: 2rem;
  margin-left: auto;
}

body.rtl .agreement-info .form-group {
  margin-left: 2rem;
  margin-right: auto;
}

body.rtl .agreement-info .btn-style-1 {
  margin-right: 1.5rem;
  margin-left: auto;
}

body.rtl .agreement-info2 .search-input {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .agreement-info2-list .menu-style-2 {
  margin-right: auto;
  margin-left: 1rem;
  width: 40% !important;
}

body.rtl .otherparty .connection-actions {
  right: 75%;
  left: auto;
}

body.rtl .my-contacts-List .connection-item {
  border-left: 2px solid transparent !important;
  border-right: auto;
}

body.rtl .my-contacts-List .connection-item-right {
  margin-right: 1rem !important;
  margin-left: unset;
}

body .rtl .agreement-amounts .mat-form-field-appearance-fill .mat-form-field-flex {
  text-align: right;
}

body.rtl .my-contacts-List .connection-actions {
  margin-right: auto;
  margin-left: unset;
}

body.rtl .modal-agr .btn-style-1 i {
  margin-left: 0.5rem;
}

body.rtl .steps-agreement .far,
body.rtl .steps-agreement .fas {
  right: 0;
  left: auto;
}

body.rtl .user-dashboard-middle .connection-name,
body.rtl .user-dashboard-middle .connection-phone {
  text-align: right;
  width: 100%;
}

body.rtl .user-dashboard-middle .InvoicesList .connection-name {
  font-weight: 500;
  text-align: right;
  width: 100%;
  margin-left: 5rem;
}

body.rtl .user-dashboard-middle .connection-date,
body.rtl .user-dashboard-middle .payment-value {
  text-align: right;
  width: 100%;
}

body.rtl .user-dashboard-middle .InvoicesList .payment-method {
  margin-left: 5rem;
  text-align: right;

}

body.rtl .user-dashboard-middle .InvoicesList .connection-phone {
  margin-left: unset;
  text-align: right;
  margin-left: 5rem;
}

body.rtl .user-auth-form .agreement-actions button {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .agreement-amounts {
  text-align: right;
}

body.rtl .user-auth-form .address-actions {
  margin-right: -1.5rem;
  margin-left: auto;
}

body.rtl .agreement-info .connection-item .item-body .connection-info {
  margin-right: 1rem;
  text-align: right;
}

body.rtl .addresses-left,
.addresses-right {
  margin-left: 3rem;
  margin-right: auto;
}

body.rtl .agreement-info2-list .fa-chevron-left {
  margin-left: 1.5rem;
  margin-right: auto;
}

body.rtl .agreement-amounts .mat-select-value-text {
  margin-right: 0.5rem;
  margin-left: auto;
}

body.rtl .agreement-amounts .action-link {
  left: 1rem;
  right: auto !important;
}

body.rtl .agreement-amounts .form-group input[type="checkbox"]:checked {
  margin-right: 0.1rem;
  margin-left: auto;
}

body.rtl .agreement-amounts .col-md-8 {
  padding-right: 0.4rem;
  padding-left: auto;
}

body.rtl .otherparty .connection-item-right {
  margin-right: 4rem !important;
  margin-left: auto;
}

body.rtl .otherparty .action-link.default {
  right: 2rem;
  left: auto;
}

body.rtl .add-note .sendnote {
  left: 0.3rem;
  right: auto;
}

body.rtl .showActions a .fas {
  right: 0.4rem !important;
  left: auto;
}

body.rtl .ListAgreements .showActions .connection-item-right .connection-actions {
  left: 0;
  right: auto;
}

body.rtl button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted {
  right: 30rem !important;
  left: auto;
}

body.rtl .carousel[_ngcontent-xia-c391]>button[_ngcontent-xia-c391]:last-of-type {
  left: 0 !important;
  right: 33rem !important;
}

body.rtl .Amount-title {
  margin-right: 0.2rem;
  margin-left: auto;
}

body.rtl .agr-invoices .mat-form-field-suffix .mat-icon {
  padding-left: 0.8rem;
  padding-right: unset !important;
}

body.rtl table .AmountsTable .heading-3 {
  text-align: unset;
}

body.rtl .AmountsTable td.col-md-4 {
  text-align: right;
}

body.rtl .Transactions table td {
  text-align: right;
  padding-right: 1rem
}

body.rtl .AdditionalFields .danger {
  left: 0;
  right: unset !important;
}

body.rtl .details-info .qr {
  left: 2rem;
  right: unset !important;
}

body.rtl .Notifications-container ul.menu-style-2 {
  right: 15rem;
  left: unset !important;
}

body.rtl .Info-rows td {
  text-align: right;
}

body.rtl .AmountsTable .Invoice-details-text1 {
  text-align: right;
}

body.rtl .reciept-additional-info {
  text-align: right;
}

body.rtl .InvoiceDetails .heading-2 {
  text-align: right;
}

body.rtl .agr-invoices .sorting-dropdown .fas {
  left: 0;
  right: auto;
}

body.rtl .amount-info {
  left: 7rem;
  right: auto;
}

body.rtl .Payment-sched .profile-verified-notice i {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .MyInvoices .connections-list.InvoicesList .connection-photo {
  margin-right: 0.4rem;
  margin-left: auto;
}

body.rtl .Payment-sched .connection-photo {
  margin-right: 0.4rem;
  margin-left: auto;
}

body.rtl .agreements-docs-button .agreements-font2 {
  margin-right: 1rem;
}

body.rtl .Inner-Invoice-item .connection-info {
  margin-right: 1rem;
}

body.rtl .actions .form-group input[type="checkbox"] {
  margin-left: 1rem;
}

body.rtl .ListAgreements.view-customers .sorting-dropdown .Sort-agreements {
  left: 4% !important;
  right: unset !important;
}

body.rtl .view-customers .sorting-dropdown {
  margin-left: unset !important;
}

body.rtl .Payment-sched .btn-style-10 {
  margin-right: auto;
  left: 5% !important;
  right: unset !important;
  margin-left: auto;
}

body.rtl .Agreement-details .Amount-title .list-types {
  margin-left: 15rem !important;
  padding-right: unset;
  padding-left: auto;
  margin-right: auto;
}

/* body.rtl .filters-responsive {
  left: 2rem;
  right: auto;
} */

body.rtl .Payment-sched .invoices-body .invited,
body.rtl .Payment-sched .invoices-body .invoice-date {
  right: 75% !important;
  left: auto;
}

body.rtl .DetailsIcons {
  margin-right: 3.3rem !important;
  margin-left: unset !important;
}

body.rtl .Payment-sched .sorting-dropdown .btn-style-5,
body.rtl .agr-invoices .sorting-dropdown .btn-style-5 {
  margin-left: auto;
}

body.rtl .Payment-sched .sorting-dropdown span {
  margin-right: auto;
}

body.rtl .View-agreement-container .settings-labelstyle3 {
  left: 1rem;
  right: auto;
}

body.rtl .checkmark {
  right: 0.5rem;
  left: auto;
}

body.rtl .SelectTopay .connection-actions {
  margin-left: 0.2em;
  margin-right: auto;
  right: -2rem;
  left: auto;
}

body.rtl .SelectTopay .connection-item {
  margin-right: 1rem !important;
  margin-left: auto;
}

body.rtl .relative-position .Payments-Details {
  margin-right: 4rem;
  margin-left: auto;
}

body.rtl .payment-method ul.list-types {
  margin-left: unset;
}

body.rtl .Agreement-details .headingstyle1 .payment-method {
  margin-right: 2rem;
}

body.rtl .InvoiceDetails table .connection-phone {
  margin-left: unset !important;
}

body.rtl .Invoices-Container .Details-container .InvoiceDetails table {

  text-align: right;
}

@media screen and (max-width: 690px) {
  body.rtl .Invoices-Container .Details-container .InvoiceDetails table {
    width: 100% !important;
    border: unset !important;
    text-align: right;
  }

  body.rtl .Payments-Actions .back-button {
    float: right;
  }
}

body.rtl .available-balance {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .Amount-title .select {
  margin-right: 4rem;
  margin-left: auto;
}

body.rtl .Payment-sched .payments-listing .invited {
  right: 77%;
  left: auto;
}

body.rtl .payment-list .image .agreement-status {
  padding-left: 0.2rem;
  padding-right: auto;
}

body.rtl .agr-invoices .Payment-sched .back-button {
  right: 5rem;
  left: auto;
}

body.rtl .Payments-Details img {
  margin-right: unset !important;
  margin-left: auto;
}

body.rtl .confirm-payment .agreementpaymentsList ul li {
  margin-right: unset !important;
}

body.rtl .confirm-payment .agreementpaymentsList ul li span {
  margin-right: 1.5rem !important;
}

body.rtl .confirm-payment ul input[type=radio] {
  margin-left: 0.5rem;
  margin-right: auto;
}

body.rtl .confirm-payment {
  text-align: right;
}

body.rtl .AllAccounts label {
  margin-right: 2rem;
  margin-left: auto;
}

body.rtl .AllAccounts .list-1 {
  margin-left: 1rem;
  margin-right: -2rem;
}

body.rtl .blockedaccounts-settings .settings-info-container {
  text-align: right;
}

body.rtl .confirm-payment .connection-item .fas {
  right: 1rem;
  left: auto;
}

body.rtl .ml-5,
.mx-5 {
  margin-right: 2rem !important;
  margin-left: 0 !important;
}

body.rtl .helpsection-leftmenu ul li {
  justify-content: right;
}

body.rtl .contact-info-layer .form-group.verified .info-field i,
body.rtl .contact-info-layer .form-group.not-verified .info-field i {
  left: 0.2rem;
  right: auto;
}

body.rtl ul.menu-style-1 li a {
  text-align: right;
}

body.rtl .form-group .input-group-prepend {
  top: 3.3rem !important;
}

body.rtl .loginform .iti.separate-dial-code input,
body.rtl .register-form .iti.separate-dial-code input,
body.rtl .resetPass .iti.separate-dial-code input,
body.rtl .loginform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input,
body.rtl .resetPass .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input,
body.rtl .register-form .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
  direction: ltr;
  text-align: right;
}

body.rtl .contactus-page .iti.separate-dial-code input,
body.rtl .contactus-page .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
  direction: ltr;
  text-align: right;
}

body.rtl .matensa-number {
  direction: ltr;
}

@media screen and (max-width: 1025px) {

  body.rtl .loginform .iti.separate-dial-code input,
  body.rtl .resetPass .iti.separate-dial-code input,
  body.rtl .loginform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input,
  body.rtl .resetPass .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
    padding-left: 45% !important;
  }

  body.rtl .contactus-page .iti.separate-dial-code input,
  body.rtl .contactus-page .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
    padding-left: 45% !important;
  }
}

body.rtl app-register .bottom-actions {
  left: 25% !important;
}

body.rtl .verified-icon i:last-child {
  top: 50%;
  transform: translateY(-50%);
}

body.rtl .notification-block .fas {
  margin-left: 0.5rem;
}

body.rtl .confirm-payment .connection-item .connection-item-right {
  margin-right: unset !important;
  margin-left: auto;
}

body.rtl .notifications-dropdown .menu-style-2 {
  width: 62%;
}

body.rtl .dashboard-left .connections-filters li {
  display: flex;
}

body.rtl .confirm-payment.Agreements-rightpart.MyInvoices .cancelPay i {
  right: 0.45rem !important;
  left: auto;
  line-height: 0.8rem !important;
  font-size: 0.8rem !important;
}

body.rtl .iti .selected-dial-code {
  direction: ltr;
}

@media screen and (max-width: 1025px) {
  body.rtl .Pay-Now .pay-now-back {
    left: unset !important;
    top: 10rem !important;
    right: 1rem !important;
  }

  body.rtl .MyPayments.Pay-Now .headlines {
    width: 70% !important;
    right: 0rem !important;
  }
}

@media screen and (max-width: 960px) {
  body.rtl .confirm-payment .settings-bottom-buttons button {
    margin-right: unset !important;
  }
}

body.rtl .Pay-Now .headlines .headingstyle2 {
  margin-left: 1rem;
  margin-right: unset !important;
}

body.rtl .Pay-Now .headlines {
  right: auto !important;
  left: 3rem;
}

body.rtl .Pay-Now .pay-now-back {
  left: unset !important;
  right: 6rem;
}

body.rtl .accountbalance-Info .AllAccounts img {
  right: -2.2rem;
  left: auto;
}

body.rtl .account-verif-main .Agreements-rightpart .connection-item .fas {
  right: 1rem !important;
  left: auto;
}

body.rtl .AllAccounts {
  left: 0rem;
  right: auto;
}

body.rtl .Payfrom-account span {
  margin-right: 3rem;
  margin-left: auto;
  float: right;
}

body.rtl .Payfrom-account i {
  margin-right: auto;
  margin-left: 0.5rem;
}

body.rtl .confirm-payment .cancelPay {
  left: -0.5rem;
  right: auto;
}

body.rtl .confirm-payment span.invited,
body.rtl .confirm-payment span.invoice-date {
  right: 70% !important;
  left: unset !important;
}

body.rtl .confirm-payment .agreementpaymentsList ul {
  padding-right: 0rem;
  padding-left: auto;
}

body.rtl .payment-list .Payment-sched .invited {
  right: 55% !important;
  left: auto;
}

body.rtl .agr-invoices .sorting-dropdown .Sort-agreements {
  left: -1rem;
  right: auto;
}

body.rtl .agr-invoices .InvoicesList .connection-item .fas {
  right: 1.8rem;
  left: auto;
}

body.rtl .agr-invoices .Agreements-rightpart .image .agreement-status {
  right: -1.3rem;
  left: auto;
}

body.rtl .InvoiceDetails img {
  margin-right: -5rem;
  margin-left: auto;
}

body.rtl .added-input .action-link {
  left: 10px;
  right: auto;
}

body.rtl .InvoicesList .View-agr-options {
  right: 100% !important;
  left: auto;
}

body.rtl .InvoicesList .invoice-date {
  right: 70% !important;
  left: auto;
}

body.rtl .MyInvoices .InvoicesList .invited {
  right: 70% !important;
  left: auto !important;
}

body.rtl .payments-listing .connection-item .fas {
  right: 1rem;
  left: auto;
}

body.rtl .payments-listing span.invited,
body.rtl .payments-listing .invoice-date {
  right: 77% !important;
  left: auto !important;
}

body.rtl .invoices-body .settings-labelstyle3 {
  left: 3rem !important;
  right: auto;
}

body.rtl .Payment-sched .payments-listing .Inner-Payment-item .invited {
  right: unset !important;
  left: auto;
  margin-right: 1rem;
  margin-left: auto;
}

body.rtl .Payment-sched .payments-listing .Inner-Payment-item .invoice-date {
  right: 1rem !important;
  left: auto;
}

body.rtl .Payment-sched .InvoicesList .Inner-Invoice-item .invoice-date {
  right: 5.4rem !important;
  left: auto;
}

body.rtl .Agreements-rightpart .Inner-Invoice-item .invited {
  right: 5.4rem !important;
  left: auto;
}

body.rtl .Payment-sched .payments-listing .connection-item-right {
  margin-right: 1rem !important;
  margin-left: auto;
}

body.rtl .payments-listing .connection-item-right {
  margin-right: 3rem !important;
  margin-left: auto;
}

body.rtl .Currencies {
  right: 0.4rem;
  left: auto;
}

body.rtl .Currencies span {
  padding-right: 1rem;
  padding-left: auto;
}

body.rtl .MyInvoices .settings-bottom-buttons .btn-style-5 {
  margin-right: 2rem;
  margin-left: auto;
}

body.rtl .other-documents a {
  margin-right: auto;
  margin-left: auto;
  left: 15px !important;
  right: auto !important;
}

body.rtl .agreement-payment-container .action-link.danger {

  line-height: 1.3rem;
  font-size: 0.8rem;
}

body.rtl .search-input input[type="text"] {
  text-indent: 0.2rem;
}

body.rtl .gray-notice {
  text-align: right;
  line-height: 1.8rem;
}

body.rtl .red-dot {
  left: 0;
  margin-right: 0.5rem;
  margin-left: auto;
  line-height: 0.8rem !important;
}

body.rtl .send-money-container .AllAccounts label {
  margin-right: unset !important;
}

body.rtl .document-block input[type="checkbox"] {
  left: 0.5rem !important;
}

body.rtl .RedeemPointsList .fa-history {
  left: 0;
  right: unset !important;
}

body.rtl .RedeemPointsList .profile-qrcode {
  top: 2rem !important;
  position: relative;
}

body.rtl .connections-container .total-matensa {
  right: 37rem;
  left: unset !important;
}

body.rtl .user-auth-form .back-button {
  right: 0rem;
  left: auto;
}

body.rtl .documents-row1 ul {
  margin-left: auto;
  margin-right: unset;
}

body.rtl .modal-agr .btn-style-5,
body.rtl .modal-agr .btn-style-1 {
  margin-right: 1rem;
  margin-left: auto;
}

body.rtl .headlines {
  margin-right: auto;
  left: 0;
  right: auto;
}

body.rtl .dot,
body.rtl .dot1,
body.rtl .dot2 {
  margin-left: 1rem;
}

body.rtl .view-agreement {
  text-align: right;
}

body.rtl .terms-types label {
  margin-right: 2.5rem;
  margin-left: auto;
}

body.rtl .agreement-payment-container .profile-verified-notice {
  margin-right: 0.2rem;
  margin-left: auto;
}

body.rtl .group-filters .connections-search,
body.rtl .group-filters .sorting-filters {
  margin-left: 1rem;
}

body.rtl .agreement-payments-actions i {
  margin-right: 0.5rem;
  margin-left: auto;
}

body.rtl .group-filters .Sort-agreements {
  left: 4%;
  right: unset;
}

body.rtl .agreement-payment-container {
  margin-left: 2rem;
}

body.rtl .documents-info {
  float: left;
}

body.rtl .documents-info-dot {
  margin-left: 0.5rem;
}

body.rtl .remove-payment {
  left: 2rem;
  right: auto;
}

body.rtl .Payment-sched .Agreements-rightpart {
  border-right: unset;
  border-left: auto;
}

body.rtl .connection-labelstyle1 i:hover .profile-image-options {
  right: -2rem;
  left: auto;
}

body.rtl .connection-labelstyle1 .profile-image-options .role {
  font-family: 'Poppins', sans-serif !important;
}

body.rtl .View-agreement-container .dashboard-middle {
  margin-right: -0.1rem;
  margin-left: auto;
  border-top-right-radius: unset;
  border-top-left-radius: auto;
}

body.rtl .View-agreement-container .dashboard-settings-left {
  border-top-left-radius: unset;
  border-top-right-radius: auto;
}

body.rtl .View-agreement-container .dashboard-left ul {
  margin-right: -3rem;
  margin-left: auto;
}

body.rtl .settings-list {
  margin-right: -3rem;
  margin-left: auto;
  text-align: right;
}

body.rtl .choose-account-container {
  margin-right: 8rem !important;
  margin-left: 7rem;
}

body.rtl .payment-list .invited {
  right: 80% !important;
  left: auto;
}

body.rtl .MyInvoices .btn-style-10 {
  margin-right: auto;
  left: 9%;
  right: auto !important;
}

body.rtl .invoice-date {
  right: 77% !important;
  left: auto;
}

body.rtl .MyInvoices .image .agreement-status {
  padding-left: 0.2rem;
  padding-right: auto;
}

body.rtl .NoPaymentIcon {
  right: 1rem !important;
  left: auto;
}

body.rtl .document-block input[type="checkbox"]:checked:before {
  padding-right: 0.2rem;
  padding-left: auto;
}

body.rtl .templates-drafts .back-button {
  margin-left: -1rem;
  margin-right: auto;
}

body.rtl .Fees {
  right: 17%;
  left: auto;
}

body.rtl .Fees:before {
  left: 45%;
  right: auto;
}

body.rtl .fixed-fee {
  right: 40%;
  left: auto;
}

body.rtl .nav-header #ddcurrency .dropdown-menu {
  right: -6rem !important;
  left: unset !important;
  transform: unset !important;
}

body.rtl .import-file .btn-style-5 {
  left: 3rem;
  right: auto;
}

body.rtl .upload-amounts-table i {
  left: 1rem;
  right: auto;
}

body.rtl .upload-amounts-table .connection-actions-options {
  left: 0;
  right: auto;
}

body.rtl table th:last-child {
  border-top-left-radius: 8px;
  border-left: unset;
  border-right: auto;
  border-top-right-radius: 0;
}

body.rtl .mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  min-width: unset !important;
  left: 0 !important;
  right: unset !important;
}

body.rtl table thead tr:first-child th:first-child {
  border-top-right-radius: 8px;
  border-right: unset;
  border-left: auto;
  border-top-left-radius: 0;
}

body.rtl .agr-invoices .Payments-Actions .back-button {
  right: 6rem;
  left: auto;
}

body.rtl .Receipts-part {
  margin-right: auto;
  margin-left: auto;
}

body.rtl .modaltitle {
  padding-right: 0.8rem;
  padding-left: auto;
}

body.rtl .MyInvoices .Agreements-rightpart {
  border-right: unset;
  border-left: auto;
}

body.rtl .detailsRightSide {
  text-align: left !important;
}

body.rtl .InvoiceFooter {
  right: 9rem;
  left: auto;
}

body.rtl .connections-mainInfo .connection-info-container {
  text-align: right;
}

body.rtl .secured-matensa i {
  margin-left: 1rem;
}

body.rtl .help-matensa i {
  margin-right: 1rem;
}

body.rtl .createInvoice a i.fa-arrow-circle-left {
  right: 5rem;
  left: auto;
}

body.rtl .DetailsShown {
  padding-right: 3.5rem !important;
  padding-left: auto;
}

body.rtl .Agreements-rightpart .Transactions .connection-item .fas {
  right: 1rem !important;
  left: auto !important;
}

body.rtl .Transactions .connection-item-right {
  margin-right: 3rem !important;
  margin-left: auto;
}

body.rtl .connections-middle .connection-info {
  margin-right: 1rem;
  max-width: 45%;
}

body.rtl .Transactions .payment-method {
  margin-left: auto;
}

body.rtl .Transactions .connection-info {
  margin-right: 1rem !important;
}

body.rtl .form-group .form-control,
body.rtl .input-control {
  text-align: right;
}

body.rtl .form-group .form-control,
body.rtl .input-control {
  text-indent: 0.3rem;
}

body.rtl .form-group .mat-select-value {
  text-align: right;
}

body.rtl .menu-bar span {
  float: left;
}

body.rtl .OverDue-Svg {
  right: 20%;
  left: auto;
}

body.rtl .connections-mainInfo .connection-info-container .label-style-1 {
  text-align: right;
}

body.rtl .agreement-amounts .btn-style-5,
body.rtl .agreements-docs-button .btn-style-5 {
  line-height: 2.2rem;
  min-width: unset;
  width: 48px;
  display: unset;
  font-size: 2rem;
  border-radius: 50%;
  margin: auto;
}

body.rtl .download-pdf {
  left: 2rem;
  right: auto;
}

body.rtl .mr-3,
body.rtl .mx-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important;
}

body.rtl .Exc-Mark {
  right: 1.5rem;
  left: auto;
}

body.rtl .mr-2,
body.rtl .mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}

body.rtl .templates-drafts {
  margin-left: auto !important;
  margin-right: auto !important;
}

body.rtl .missing-docs {
  right: 5.5rem;
  left: auto;
}

body.rtl td .payment-method {
  float: left;
}

body.rtl .payment-type .form-group {
  margin-left: 1rem;
  margin-right: auto;
}

body.rtl .View-agreement-container .documents-info {
  left: 2rem;
  right: auto;
}

body.rtl .agreement-value .form-group input[type=radio] {
  margin-left: 0.7rem;
}

body.rtl .istemplate {
  text-align: right;
}

body.rtl .View-agreement-container {
  text-align: right;
}

body.rtl .istemplate i {
  margin-left: 1rem;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body.rtl .service-item {
  text-align: right;
}

body.rtl .service-icons {
  right: unset !important;
  left: 3rem;
}

body.rtl .service-price {
  right: unset !important;
  left: 3rem;
}

body.rtl .service_title {
  text-align: right;
}

body.rtl .ReserveNow {
  text-align: right;
}

body.rtl .bookingservice-info .fa-heart {
  right: unset !important;
  left: 0;
}

body.rtl .specific_fixed {
  right: unset !important;
  left: 0;
}

body.rtl .bookingservice-info {
  text-align: right;
}